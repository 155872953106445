/* eslint-disable import/no-anonymous-default-export */
export default {
  //Navabr
  nav_item1: "Home",
  nav_item2: "Sollicitant",
  nav_item3: "Blog",
  nav_item4: "Tarieven",
  nav_item5: "OVER ONS",
  nav_item6: "VACATURES",
  /// notification
  seeAll: 'Alles zien',
  noDataFound: 'Geen data gevonden',
  seeMore: 'Bekijk meer',
  notificaton: 'Meldingen',
  //User Drop menu
  user1: "Profiel",
  user2: "Creëer",
  user3: "Mijn Vacactures",
  user5: "Chat",
  logout: "Uitloggen",
  applyJob: 'Vacaturegeschiedenis toepassen',
  favorite: 'Favoriet',


  //Home Page
  home_heading1: "Vacatures voor",
  home_cat1: "Zwemonderwijzer",
  home_cat2: "Toezichthouder",
  home_cat3: "Facilitaire dienst medewerker",
  home_cat4: "Technische dienst medewerker",
  home_cat5: "Locatiemanager",
  home_cat6: "Coordinator zwemzaken",
  home_cat7: "Receptionist",
  home_cat8: "Administratief medewerker",
  home_cat9: "Horecamedewerker",
  home_cat10: "Host",
  home_parag: "Verken alle vacatures en functies binnen de zwembadbranche, inclusief zwembaden, zwemscholen, zwemverenigingen en parken of campings met een zwembad.",
  home_plholder1: "Zoeken",
  home_plholder2: "Selecteer Dienstverband",
  home_plholder3: "Selecteer Categorie",
  home_heading2: "Functiecategorieën",
  home_heading3: "Recente Vacatures",
  home_all_job_btn: "Bekijk Alle Vacatures",
  home_popup: "Deze website gebruikt cookies om je een betere gebruikservaring te bieden. Door deze te gebruiken, accepteer je ons gebruik van cookies..",
  home_popup_btn: "Accepteer alle cookies",
  login_alert: "Succesvol ingelogd",
  home_popup_btn2: 'Stel voorkeuren in',
  home_popup_btn3: 'Opslaan en accepteren',
  home_popup_btn4: 'Terug naar instellingen',
  //Footer
  TnC: "Algemene Voorwaarden",
  Faq: "FAQ",
  info: "Contact",

  //Applicant Page
  appli_heading1: "Lijst van Sollicitanten",
  appli_parag: "Er zijn geen gegevens om weer te geven",
  appli_drop1: "Shortlist / Afgewezen",
  appli_drop6: "Shortlist",
  appli_drop2: "Afgewezen",
  appli_drop5: "Afgewezen Interview",
  appli_drop3: "Interview",
  appli_drop4: "Alle Sollicitanten",
  //Blog Page
  blog_rm: "Lees Meer",
  blog_prev: "Vorige",
  blog_next: "Volgende",
  blog_not: 'Blogs niet beschikbaar',

  //pricing page
  pri_heading: "Abonnementen",
  pri_parag: "Met het gratis basispakket kun je optimaal gebruik maken van alle tools op ons platform voor het plaatsen van twee vacatures. Wil je meer dan twee vacatures plaatsen? Dan vragen we een kleine maandelijkse bijdrage. De details hiervan vind je hieronder.",
  pri_mon: "Maandelijks",
  pri_year: "Jaarlijks",
  free: "GRATIS",
  free1: "Plaats gratis 2 vacatures: Maak optimaal gebruik van ons platform met twee gratis vacatureplaatsingen, inclusief uitgebreide promotiepakketten en automatiseringsopties.",
  free2: "Gebruik alle tools gratis: Profiteer van onze geavanceerde functies zoals vooraf ingevulde templates, gratis chatmodule en automatische e-mails voor een efficiënt wervingsproces.",
  free3: "Bereik een breed publiek door promotie van vacatures via onze partner Zwembadvergelijker.nl",
  free_btn: "Begin Gratis",
  pre_pack: "PREMIUM PAKKET",
  pre_sen: "inclusief 21% belasting",
  pre1: "Bevat alle functies van het Standaardpakket tot maximaal 6 vacatures",
  pre2: "Uitgebreid digitaal mediapakket: Ontvang een werkenindezwembadbranche.nl mediapakket voor elke vacature, ideaal voor promotie op je eigen social media kanalen.",
  pre3: "Maximale zichtbaarheid: Alle vacatures worden gedeeld via de social media kanalen van werkenindezwembranche.nl en zwembadvergelijker.nl, voor een bredere en gerichte bereikbaarheid.",
  pre_btn: "Neem Premium",
  p_year: 'Jaar',
  p_mon: 'Maand ',
  //about page
  abt_heading: "Neem Contact Op Met Werkenindezwembadbranche.nl",
  abt_heading2: "Visie",
  abt_heading3: "Missie",
  abt_heading4: "Onze Geschiedenis",
  form_name: "Uw Naam",
  form_email: "Uw E-mail",
  from_phone: "Uw Telefoonnummer",
  form_subject: "Onderwerp",
  form_comm: "Opmerkingen",
  form_comm_plhold: "Bericht",
  from_btn: "Verzend Bericht",

  //job List page
  job_heading: "Aanbevolen Vacatures",
  rel_job: "Meest Relevante Vacature",
  job_filter: "Filter",
  filter_heading1: "Categorieën",
  filter_heading2: "Land",
  filter_heading3: "Stad",
  filter_heading4: "Betaalde Training",
  filter_heading5: "Functietype",
  filter_btn: "Filter Wissen",
  all_Jobs: 'Alle banen',

  //Favourite
  fav_heading1: "Favorieten",
  fav_sen: "Geen Favoriete Vacatures Gevonden!",
  job_not: "Vacature niet gevonden!",

  //User profile page
  prof_heading1: "Persoonlijke Informatie",
  first_name: "Voornaam",
  last_name: "Achternaam",
  phone: "Telefoon",
  address: "Adres",
  descrip: "Korte functiebeschrijving",
  prof_btn: "Bewerk",
  prof_btn1: "Opslaan",
  prof_btn2: "Annuleren",
  comp_details: "Bedrijfsgegevens",
  logo: "Bedrijfslogo",
  com_name: "Bedrijfsnaam",
  com_url: "Bedrijfswebsite URL",
  com_descrip: "Bedrijfsomschrijving",
  com_Address: "Bedrijfsadres",
  //change password
  chan_h1: 'Verander wachtwoord',
  chan_olf: 'Oud Wachtwoord',
  chan_new: 'nieuw paswoord',
  chan_conf: 'bevestig wachtwoord ',
  chan_update_btn: 'Vernieuw wachtwoord',
  //Create Job page
  job_tit1: "Vacature categorie",
  job_tit2: "Functie titel",
  job_tit2_plc: "Voer functietitel in",
  add_img: "Afbeelding toevoegen",
  motive_doc: "Motivatiedocument",
  job_req: "Functietitel",
  job_res: "Functieverantwoordelijkheden",
  job_quali: "Functiekwalificaties",
  job_des: "Functieomschrijving",
  cao: "Selecteer CAO",
  job_scale: "Selecteer Functieschaal",
  job_period: "Selecteer Functieperiode",
  age: "Leeftijd",
  max_salary: "Maximaal Salaris",
  max_salary_plc: "Functie salaris",
  job_sen: "Wij betalen voor je opleiding zodat je jouw functie goed kunt uitvoeren",
  job_btn: "Vacature Aanmaken",

  aboutCompany: 'Over bedrijf',
  //My Jobs page
  myjob_heading: "Mijn Aangemaakte Vacatures",
  myjob_btn: "Vacature Aanmaken",
  title: "Titel",
  category: "Categorie",
  scale: "Schaal",
  period: "Periode",
  salary: "Salaris",
  jobLoc: "Functielocatie",
  Responsi: "Verantwoordelijkheden",
  dateAt: "Aangemaakt Op",
  share: "Delen",
  action: "Actie",
  job_note: 'Je hebt al gesolliciteerd voor deze functie',
  applyNow: 'Solliciteer hier',
  applyContract: 'Contract toepassen',
  expiredJob: 'Deze taak is verlopen.',
  //Chat page
  chat_sen: "Selecteer een bericht",
  chat_sen2: "Kies uit je bestaande gesprekken, start een nieuwe, of blijf gewoon zwemmen.",

  //SignUp page
  sign_heading: "Registreren",
  conf_pass: "Bevestig Wachtwoord",
  location_btn: "Gebruik je huidige locatie",
  user_type: "Gebruikerstype",
  user_type1: "",
  user_type2: "",
  act_tnc: "Ik Accepteer de Algemene Voorwaarden",
  reg_btn: "Registreren",
  already: "Heb je al een account",
  signin: "Inloggen",

  //Signin page
  login: "Inloggen",
  forget_pass: "Wachtwoord Vergeten",
  signup: "Registreren",

  //job Details page
  details: "Bedrijfsdetails",
  resNduties: "Bedrijfsdetails",
  detail_req: "Vereiste Ervaring, Vaardigheden",
  deatils_sen: "Je hebt al gesolliciteerd voor deze baan",
  yes: "Ja",
  job_details: "Functiedetails",
  paidInfo: 'Betaalde trainingen zijn cursussen waarvoor je tegen betaling een certificaat ontvangt waarin jouw competenties voor het vervullen van een bepaalde rol wordt bevestigd. Om bijvoorbeeld zwemles te geven, moet je een specifieke opleiding volgen die jou certificeert als zweminstructeur',
  location: 'Plaats',
  //Application Details
  app_details: "Sollicitatiedetails",
  app_title1: "Functietitel (welke type functie gewenst)",
  resume: "CV",
  linkedin: "LinkedIn Profiel",
  upload_Resume: 'Upload CV',
  git: "GitHub",
  upload: 'Upload',
  app_btn: "Open",
  noAttach: 'Geen bijlage',
  upload_motivation: 'Motivatiedocument uploaden',
  motivation_doc: 'Motiverend document',
  press: 'Druk op Enter nadat u het woord heeft ingevoerd.',
  //Work Experince page
  skills: "Vaardigheden",
  work_exp: "Werkervaring",
  exp_period: "Werkperiode",
  edu: "Opleiding",
  job_plc: "Accountant",
  degree: "Diploma",
  degree_plc: "BSc",
  Education: 'Opleiding',
  institute: "Naam opleiding",
  institute_plc: "NFC",
  grad_year: "Afstudeerjaar",
  addEducation: 'Opleiding toevoegen',
  qualifications: 'Kwalificaties',
  //apply job page
  appilied_heading: "Geschiedenis van gesolliciteerde Banen",

  //job types
  job_type1: "Deeltijd",
  job_type2: "Voltijd",
  job_type3: "Op Afstand",
  job_type4: "Hybride",
  ////  
  dateposted: 'Datum geplaatst',
  ////Disclaimer & Signature
  disc_h1: `Disclaimer en toestemming voor gegevensverwerking`,
  disc_sm_h1: 'Voorwaarden ondertekenen',
  disc_p1: `Door je persoonlijke en professionele informatie in te vullen en te verzenden via dit platform, ga je akkoord met het volgende:`,
  disc_h2: `Gegevensverzameling:`,
  disc_p2: `Je bevestigt dat alle verstrekte informatie correct en actueel is. Je begrijpt dat deze informatie zal worden gebruikt voor wervings- en selectiedoeleinden door potentiële werkgevers en recruiters die toegang hebben tot dit jobboard.`,
  disc_h3: `Gegevensverwerking:`,
  disc_p3: `Je geeft toestemming voor het verzamelen, verwerken en opslaan van je persoonsgegevens door het jobboard en de bijbehorende dienstverleners. Dit omvat, maar is niet beperkt tot, het delen van je gegevens met potentiële werkgevers en partners van het jobboard voor vacaturematching en andere arbeidsbemiddelingsdiensten.`,
  disc_h4: `Privacybeleid:`,
  disc_p4: `Je hebt kennis genomen van het privacybeleid van het jobboard, dat uitlegt hoe je informatie wordt verzameld, gebruikt en beschermd. Je kunt het privacybeleid raadplegen op onze website. Recht op inzage en correctie: Je hebt het recht om je gegevens in te zien, te corrigeren of te verwijderen. Voor verzoeken of vragen hierover kun je contact opnemen met onze klantenservice via support@werkenindezwembadbranche.nl`,
  disc_h5: `Toestemming intrekken:`,
  disc_p5: ` Je kunt je toestemming voor de verwerking van je persoonsgegevens op elk moment intrekken door contact op te nemen met ons via de bovenstaande contactgegevens. Houd er rekening mee dat dit geen invloed heeft op de rechtmatigheid van de verwerking voordat je toestemming werd ingetrokken.`,
  disc_p6: `Door te signeren of een digitale handtekening te zetten, bevestig je dat je deze disclaimer hebt gelezen, begrepen en hiermee akkoord gaat.`,

  /// digital signature

  _sign: 'Handtekening',
  digital_sign: 'Digitale handtekening',
  digital_upload: 'Afbeelding uploaden',
  digital_save: 'Opslaan',
  digital_clear: 'Leegmaken',
  digital_file: 'Upload bestand',
  digital_applicant: 'Handtekeningen van de aanvrager',
  digital_chat: 'Chatten met Werkgever',
  digital_apply: 'Toepassen',

  //table
  int_Date: 'Interviewdatum',
  int_Time: 'Interviewtijd',
  int_Invite: 'Uitnodiging interview',
  int_Invite_btn: 'Uitnodigen',

  /// description
  msg: 'Schrijf een bericht',
  reject: 'Afwijzen',

  //Apply Job
  app_h1: 'Werkgelegenheidsaanvraag',
  app_p1: 'Onze sollicitatieaanvraag verbindt je met ons levendige netwerk. Dit uitgebreide formulier verzamelt cruciale informatie, zoals persoonlijke gegevens, opleidingsachtergrond, werkervaring, vaardigheden en referenties. Vul alle secties zorgvuldig in en voeg je CV en eventuele relevante documenten toe. We waarderen diversiteit en bieden een platform met gelijke kansen voor iedereen. Door te ondertekenen bevestig je de juistheid van de verstrekte gegevens. Bedankt dat je Werkenindezwembranche.nl kiest als jouw platform voor carrièremogelijkheden; we kijken ernaar uit om je te koppelen aan een geschikte werkgever. We gaan ervan uit dat de werkgever altijd contact met je zal opnemen.',
  note1: 'Je wilt gaan solliciteren op de vacatures? Geweldig! Voordat je aan de slag kunt, hebben we eerst wat gegevens van je nodig. Klik op de button om het sollicitatieformulier in te vullen en je avontuur te beginnen!',
  btn_click: 'Klik',
  dob: 'Geboortedatum',
  ssn: 'Burgerservicenummer (BSN)',
  address1: 'Adres regel 1',
  address2: 'Adres regel 2',
  state: 'Staat',
  street: 'Adres',
  zipCode: 'Postcode',
  app_info: 'Aanmeldingsinformatie',
  availableToStart: 'Wanneer ben je beschikbaar om te starten?',
  wage: 'Gewenst brutoloon',
  positionApplied: 'Positie aangevraagd',
  timeCall: 'Beste tijd om te bellen',
  morning: 'Ochtend',
  evening: 'Avond',
  citizen: 'Spreek je de Nederlandse taal?” ',

  //login
  password: 'Wachtwoord',
  signIn: 'Aanmelden',
  account: `Heb je geen account?`,
  profile: 'Profiel',
  i_accept: 'Ik aanvaard',
  employer: 'werkgever',
  candidates: 'sollicitanten',

  ///  Terms & Condition
  tnc_h1: 'Abonnementsdiensten',
  tnc_sub_h1_h1: 'Abonnementen',
  tnc_sub_h1_h2: 'Abonnementstermijn',
  tnc_sub_h1_h3: 'Betaling',
  tnc_sub_h1_h4: 'Wijziging en annulering',

  tnc_h2: 'Vacature',
  tnc_sub_h2_h1: 'Vereisten voor het plaatsen van berichten',
  tnc_sub_h2_h2: 'Eigendom van inhoud',
  tnc_sub_h2_h3: 'Taakverwijdering',

  tnc_h3: 'Sollicitatie',
  tnc_sub_h3_h1: 'Aanmeldingsprocedure',
  tnc_sub_h3_h2: 'Privacy',

  tnc_h4: 'Disclaimers en beperking van aansprakelijkheid',
  tnc_sub_h4_h1: 'Vrijwaring',
  tnc_sub_h4_h2: 'Beperking van aansprakelijkheid',

  tnc_h5: 'Algemene bepalingen',
  tnc_sub_h5_h1: 'Toepasselijk recht',
  tnc_sub_h5_h2: 'Wijziging',
  tnc_sub_h5_h3: 'Deelbaarheid',


  sub_h1_p1: `Wij bieden verschillende abonnementen aan voor werkgevers om vacatures te plaatsen op ons platform. Elk abonnement heeft zijn eigen kenmerken, beperkingen en prijzen. Door je te abonneren op een abonnement, ga je akkoord met het betalen van de toepasselijke kosten en houd je je aan de voorwaarden van dat plan.`,
  sub_h1_p2: `De abonnementstermijn begint op de datum van inschrijving en loopt door voor de duur vermeld in het plan. Het wordt automatisch verlengd voor opeenvolgende perioden, tenzij het wordt opgezegd in overeenstemming met deze Voorwaarden. De opzegtermijn is 1 kalendermaand.`,
  sub_h1_p3: `Je stemt ermee in om alle kosten die verband houden met je abonnementsplan tijdig te betalen. Als je niet betaalt, kan dit leiden tot schorsing of beëindiging van je account. Bij niet-betaling kunnen er incassokosten in rekening worden gebracht.`,
  sub_h1_p4: `Je kunt je abonnement upgraden, downgraden of annuleren op elk gewenst moment. Eventuele wijzigingen in je abonnement worden van kracht aan het begin van de volgende factureringscyclus. Er geldt geen terugbetalingsbeleid voor gedeeltelijk gebruikte abonnementsperiodes.`,

  sub_h2_p1: `Werkgevers zijn als enige verantwoordelijk voor de nauwkeurigheid en wettigheid van de vacatures die op ons platform zijn geplaatst. Vacatures moeten voldoen aan onze richtlijnen en toepasselijke wetgeving, waaronder de Algemene wet gelijke behandeling (AWGB).`,
  sub_h2_p2: `Door een vacature te plaatsen, verleen je ons een wereldwijde, niet-exclusieve, royaltyvrije licentie om de inhoud te gebruiken, reproduceren en weergeven met het oog op het leveren van onze diensten. Deze licentie geldt voor de duur van je abonnement.`,
  sub_h2_p3: `We behouden ons het recht voor om vacatures zonder overleg te verwijderen die ons beleid schenden of zonder voorafgaande kennisgeving als ongepast worden beschouwd.`,

  sub_h3_p1: `Werkzoekenden kunnen solliciteren op vacatures die op ons platform worden geplaatst. Door te solliciteren, stemmen werkzoekenden ermee in dat hun sollicitatiemateriaal met de werkgever wordt gedeeld. Werkzoekenden hebben het recht op inzage en verwijdering van hun gegevens in overeenstemming met de AVG.`,
  sub_h3_p2: `Wij doen er alles aan om de privacy van onze gebruikers te beschermen. We kunnen echter niet de veiligheid garanderen van de informatie die via ons platform naar de werkgever wordt verzonden. Gebruikers worden aangemoedigd om voorzichtig te zijn bij het delen van persoonlijke of gevoelige informatie. Onze beveiligingsmaatregelen omvatten inlog met wachtwoord en bevestiging van inlogcodes via email.`,

  sub_h4_p1: `Wij garanderen niet de juistheid, volledigheid of betrouwbaarheid van welke informatie dan ook die op ons platform wordt verstrekt. Gebruikers zijn verantwoordelijk voor het verifiëren van de authenticiteit van vacatures en de geloofwaardigheid van werkgevers.`,
  sub_h4_p2: `In geen geval zijn wij aansprakelijk voor enige directe, indirecte, incidentele, speciale of gevolgschade die voortvloeit uit of op enigerlei wijze verband houdt met het gebruik van onze diensten.`,

  sub_h5_p1: `Deze Voorwaarden worden beheerst door en geïnterpreteerd in overeenstemming met de wetten van Nederland.`,
  sub_h5_p2: `Wij behouden ons het recht voor om deze Voorwaarden op elk moment te wijzigen of bij te werken. Veranderingen worden van kracht zodra deze op ons platform worden geplaatst. Voortgezet gebruik van onze diensten betekent aanvaarding van de gewijzigde voorwaarden. Bij belangrijke wijzigingen zullen wij je vooraf informeren.`,
  sub_h5_p3: `Als enige bepaling van deze Voorwaarden ongeldig of niet-afdwingbaar blijkt te zijn, blijven de overige bepalingen volledig van kracht.`,

  /// verify code
  verify_code_h1: 'Code in e-mail',
  verify_code_h2: 'We hebben een code naar uw e-mailadres doorgestuurd',
  code: 'Voer code in',
  btn_verify: 'Code verifiëren',
  code_exp: 'tot het verlopen van de code',
  resend_code: 'Code nogmaals versturen',


  //remaining Recover Account
  recover_h1: 'Account herstellen',
  recover_p1: `Vul alstublieft jouw e-mail adres in. U zult een code ontvangen om een ​​nieuw wachtwoord aan te maken.`,
  rember: 'Onthoud je wachtwoord?',
  sendCode: 'Code verzenden',

  /// remaining  candiate Detail
  can_h1: 'Gebruikersgegevens niet gevonden!',
  can_h2: 'Sollicitatiegegevens',
  full_name: 'Voor-en achternaam',
  email: 'E-mail',
  can_status: 'Sollicitatiestatus',
  position: 'Positie',
  temp_address: 'Tijdelijk adres',
  st_Address: 'Adres',
  usCitizen: 'Nederlandse taal',
  interview_Outcome: 'Interviewresultaat',
  offer_Position: "Bied positie",
  decision_Needed: 'Beslissing nodig',
  reject_Candiate: 'Kandidaat afwijzen',
  gender: 'Geslacht',
  interviewer: 'Interviewer',
  interview_Date: 'Interviewdatum',
  interview_Time: 'Interviewtijd',
  SSN: 'BSN',
  date_Available: 'Datum beschikbaar',
  cvAttachment: 'CV-bijlage',
  notes: 'Opmerkingen',
  phone_Number: 'Telefoonnummer',
  best_time: 'Beste tijd om te bellen',
  applicants: 'Aanvragers',
  additional_Information: 'Extra informatie',
  job_start_date: 'Startdatum van de taak',
  job_start_time: 'Begintijd taak',
  bonus: 'Aanmeldingsbonus',
  bonus_notes: 'Aanmelden Bonus Opmerking',
  d_License: 'Rijbewijs',
  noAttachment: 'Geen bijlage',
  d_License_number: 'Rijbewijsnummer',
  d_License_state: 'Staat van uitgifte van rijbewijs',
  d_expiration: 'Verlopen rijbewijs',
  certificate_BLS: 'Certificaat BLS',
  certificate_EMT: 'Certificaat EMT',
  certificate_ACLS: 'Certificaat ACLS',
  w_notes: 'notities schrijven',
  view_btn: 'Bekijk het kandidatenprofiel',
  job_edit_btn: "Indienen",
  saraly_method: 'Selecteer Salarismethode',
  saraly_method1: 'Salaris toevoegen volgens de CAO.',
  v_doc: 'Document bekijken',
  saraly_method2: 'Voeg een aangepast salaris toe.',

  //// Search Cert Form
  s_head1: 'Certificaatformulier zoeken',
  s_head2: 'KandidateName',
  s_p1: 'Voordat je verder gaat, is het goed om de onderstaande gegevens te verzamelen die je vervolgens kunt uploaden. Deze informatie is bedoeld voor de (potentiële) werkgever. ',
  s_p2: `Rijbewijs`,
  s_p3: 'Staat EMT-licentie',
  s_p4: 'ACLS',
  s_note: 'Let op: u wordt gevraagd een bestaand document dat al in uw bestand staat te uploaden of uw telefoon te gebruiken om een ​​foto te maken.',
  s_head3: 'Heeft u bovenstaande documentatie bij u en kunt u deze uploaden?',
  s_no: 'Nee',
  s_yes: 'Ja',
  s_head4: 'Uploaden en invullen gegevens',
  s_cert1: `Paspoort/ ID nummer`,
  s_cert2: `Plaats van uitgifte`,
  s_cert3: `Vervaldatum Paspoort`,
  s_cert4: `Upload Paspoort/ ID`,
  s_cert5: `Upload diploma's `,
  s_cert6: `Upload 3 referenties`,
  s_cert7: `Upload ondertekend loonheffingsformulier. `,

  s_cert8: `Paspoort/ ID`,
  s_cert9: `Diploma's `,
  s_cert10: `Document referenties`,
  s_cert11: `Paspoort/ ID`,
  s_btn: `Indienen`,
  s_response: 'Bedankt voor het leveren van de extra informatie. Je formulier is succesvol verzonden. De werkgever gaat de extra informatie verzamelen en verwerken. Vervolgens mag je rekenen op een reactie.',

  s_head5: 'Heeft u bovenstaande documentatie paraat en kun je deze nu uploaden? ',
  s_head6: 'Formulier extra informatie ',
  s_p6: 'Voordat je verder gaat, is het goed om de onderstaande gegevens te verzamelen die je vervolgens kunt uploaden.',
  s_head7: 'Upload extra gegevens',
  s_l1: '	Kopie Paspoort of ID document',
  s_l2: 'Kopie diploma’s die nodig zijn voor de functie',
  s_l3: 'Een document met 3 referenties. Naam, bedrijfsnaam en telefoonnummer',
  s_l4: 'Volledig ingevuld loonheffingsformulier. Hier te downloaden: ',

  sure: 'Weet je het zeker',
  dele: 'Vacature verwijderen?',
  buy: 'Abonnement aanschaffen',
  shortlistInfo: 'Een shortlist voor kandidaten is een samengestelde selectie van topsollicitanten voor een baan, gekozen op basis van hun kwalificaties en geschikt voor de functie en het bedrijf.',
  rejectInfo: 'Zodra je een kandidaat afwijst, wordt er automatisch een e-mail verstuurd die je als werkgever vooraf kunt bekijken. Een afwijzingsmail kan maximaal één keer worden verstuurd. De kandidaat wordt vervolgens op de lijst “afgewezen” geplaatst.',
  noteStan: 'De tekst is gestandaardiseerd en kan niet worden gewijzigd.',
  statusJob: 'Statusupdate voor uw sollicitatie',
  aboutCan: 'Over kandidaat',
  sentToCan: 'Verzend naar Kandidaat',
  signatureType: 'Handtekeningtype',
  sendTo: 'Verzenden naar Werk',
  completedCan: 'Ingevuld door kandidaat',
  inviteStatus: 'Wil je de kandidaat uitnodigen?',
  rejectPerson: 'Wil je deze persoon afwijzen?',
  passport: 'Paspoort uploaden',
  idDoc: 'ID document',
  selectType: 'Selecteer type',

  idFront: 'Voorste afbeelding van identiteitskaart',
  idBack: 'Achterafbeelding van identiteitskaart',
  passportCopy: 'Kopie paspoort uploaden',
  uploadDip: 'Diplomacertificaat uploaden met certificaatnaam',
  docRef: 'Document bijvoegen met 3 referenties: naam, bedrijfsnaam en telefoonnummer',
  wagTax: 'Loonheffingsformulier',
  addMore: 'Voeg meer toe',
  placeName: "Voer de naam van de bestandsafbeelding in",

  extraInfo: 'Verzoek tot extra informatie',
  reInvite: 'Verstuur uitnodiging opnieuw',


  /// toast
  invalidFile: 'Ongeldig bestandstype. Upload een geldig bestand. je kunt alleen de bestanden ',
  selecteren: 'selecteren',
  required: 'Niet alle verplichte velden zijn ingevuld.',


  addWork: 'Werkervaring toevoegen',
  addEduc: 'Opleiding toevoegen',

  tableScroll: 'Om een vacature te bewerken, scrol naar rechts in de tabel.',
  tableScroll2: 'Om een lijst te bewerken, scrol naar rechts in de tabel.',

  subscriptionPlan: 'Abonnementsplan',
  subscription: 'Abonnement',

  accountSetting: 'Accountinstelling',
  deactivateAccount: 'Account deactiveren',
  deleteAccount: 'Account verwijderen',
  d_p1: 'Door jouw account te deactiveren, schakel je tijdelijk de toegang tot alle diensten en functies uit. Je gegevens worden veilig opgeslagen en je kunt jouw account op elk gewenst moment opnieuw activeren door opnieuw in te loggen. Bovendien kan een beheerder jouw account indien nodig opnieuw activeren.',
  d_p2: 'Door jouw account te verwijderen, verwijder je permanent al jouw gegevens en toegang tot onze diensten en functies. Deze actie is onomkeerbaar en zodra je account is verwijderd, kan deze niet meer worden hersteld.',
  /////
  inviteInfo: 'Zet de kandidaat op de shortlist voordat je deze uitnodigt.',

  //
  meetLoc: 'Vergaderlocatie',
  addLoc: 'Voeg locatie toe',
  addLoc2: 'Vergaderlocatie toevoegen',

  ///
  tHead: 'Er zijn op dit moment geen vacatures aangemaakt.',
  noRecord: 'Er zijn geen records om weer te geven',
  ///
  reqPass: 'Voer je wachtwoord in, alsjeblieft.',
  reqConPass: 'Bevestig alstublieft uw wachtwoord.',
  /// conversation

  noCon: 'Geen conversatie gevonden!',
  conh1: 'Selecteer een bericht',
  conp1: 'Kies uit je bestaande gesprekken, start een nieuw gesprek, of blijf gewoon zwemmen.',
  conp2: 'Gesprekken kunnen worden gestart zodra je als kandidaat hebt gesolliciteerd of zodra een werkgever een vacature heeft geplaatst. De gesprekken zijn 1-op-1 en worden niet gemonitord. Beide partijen ontvangen een notificatie bij nieuwe berichten.',

  download: 'Bestand wordt gedownload',
  conSend: 'Contract succesvol verzonden',
  mesgPlaceholder: 'Plaats hier je bericht….',
}