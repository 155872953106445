// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/assets/images/close.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-toggle {
  /* display: none; */
  background-color: transparent !important;
  border: none !important
}

/* styles.css */

.custom-dropdown {
  padding: 0.5rem 0;
  border-radius: 0.25rem;
}

.custom-dropdown-item {
  padding: 0.5rem 1rem;
  line-height: 1.5rem;
}

.btn-close {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
  background-size: 1.5rem !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.btn-close:focus {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,mBAAmB;EACnB,wCAAwC;EACxC;AACF;;AAEA,eAAe;;AAEf;EACE,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,oEAAkE;EAClE,kCAAkC;EAClC,uCAAuC;EACvC,6CAA6C;AAC/C;;AAEA;EACE,4DAA4D;AAC9D","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.dropdown-toggle {\n  /* display: none; */\n  background-color: transparent !important;\n  border: none !important\n}\n\n/* styles.css */\n\n.custom-dropdown {\n  padding: 0.5rem 0;\n  border-radius: 0.25rem;\n}\n\n.custom-dropdown-item {\n  padding: 0.5rem 1rem;\n  line-height: 1.5rem;\n}\n\n.btn-close {\n  background-image: url('../src/assets/images/close.png') !important;\n  background-size: 1.5rem !important;\n  background-repeat: no-repeat !important;\n  background-position: center center !important;\n}\n\n.btn-close:focus {\n  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
