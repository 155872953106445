import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './components/redux/store';
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import { I18nextProvider } from 'react-i18next';
// import { ThemeProvider } from './components/snackbar/themeContext';
import i18n from './extension/i18n.js';
// import { ThemeProvider } from './components/snackbar/themeContextProvider.js';
const App = lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense
    fallback={
      <div className="w-100 vh-100 main_app d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    }>
    {/* <ThemeProvider> */}
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <React.StrictMode>
            <App />
            <ToastContainer autoClose={3000} closeOnClick newestOnTop />
          </React.StrictMode>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
    {/* </ThemeProvider> */}
  </Suspense>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
